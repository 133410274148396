import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ImageSlider from './components/ImageSlider';
import Header from './components/Header';
import Footer from './components/Footer';
import Section from './components/Section';
import ContactForm from './components/ContactForm';
import './assets/scss/main.scss';
import useBodyClass from './hooks/useBodyClass';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const AppContent = () => {
    useBodyClass('default-class');

    return (
        <>
            <Header />
            <Routes>
                <Route
                    path="/"
                    element={
                        <main className="l-main">
                            <div className="l-main__inner">
                                <ImageSlider />
                                <Section />
                            </div>
                        </main>
                    }
                />
                <Route
                    path="/contact"
                    element={
                        <>
                            <Helmet>
                                <title>CONTACT - Fam Inc.</title>
                                <meta property="og:title" content="CONTACT - Fam Inc." />
                            </Helmet>
                            <ContactForm />
                        </>
                    }
                />
            </Routes>
            <Footer />
        </>
    );
};

const App = () => {
    return (
        <HelmetProvider>
            <Router>
                <AppContent />
            </Router>
        </HelmetProvider>
    );
};

export default App;
