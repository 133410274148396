import { useEffect } from 'react';

const useScrollClass = () => {
    useEffect(() => {
        const handleScroll = () => {
            const fixedHeight = 1;
            const scroll = window.scrollY;
            const header = document.getElementById('header');
            if (header) {
                if (scroll >= fixedHeight) {
                    header.classList.add('fixed');
                } else {
                    header.classList.remove('fixed');
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Initial check
        handleScroll();

        // Cleanup
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
};

export default useScrollClass;
