import React from 'react';

const Section = () => {
    return (
        <div>
            <section id="message" className="p-message">
                <div className="p-message__inner">
                    <div className="c-heading">
                        <h2>
                            対話から、<span>考え方をつくる。</span>
                        </h2>
                    </div>
                    <div className="c-text">
                        <p>
                            Famは、「対話」を大切にしています。
                            <br />
                            対話は、人間同士の理解を促してくれる重要な手段です。
                        </p>

                        <p>
                            Famという社名は、“マブダチ”という意味を込めました。
                            <br />
                            それはビジネスにおいて、マブダチと呼べる関係に、
                            <br />
                            大きな可能性、そして提供価値があると信じたからです。
                        </p>

                        <p>
                            丁寧に対話を繰り返していくことで、お互いを知っていく。
                            <br />
                            苦楽を共にし、時にはリラックスしながらも、成長を目指して伴走し続ける。
                            <br />
                            そうして語られなかったこと、語りにくかったことが、語られるようになる。
                        </p>

                        <p>
                            そんなマブダチ同士でつくられた考え方は、ビジネスの可能性を大きく広げ、
                            <br />
                            過去の延長線上にはない、より良い未来を生み出す提供価値になるはずです。
                        </p>

                        <p>
                            ビジネスを前進させる考え方をつくるために、
                            <br />
                            私たちは今日も、あなたと対話します。
                        </p>
                    </div>
                </div>
            </section>
            <section id="about" className="p-business">
                <div className="p-business__inner">
                    <div className="c-heading center">
                        <h2>事業内容</h2>
                    </div>
                    <div className="p-business__outline c-flex">
                        <div className="business-left">
                            <dl className="business-list">
                                <dt>MARKETING CONSULTING</dt>{' '}
                                <dd>
                                    取締役CMOの経験も含めた過去10年以上の経験や成果を踏まえて、ご支援します。よく言われる“マーケティングは経営そのものである”、は経験上、本当にそう思います。販促の手法論だけの議論や改善に留まらない、事業課題、組織課題への解決策だけでなく、中長期的に、マーケティング機能をどのように経営の考え方に強固に組み込んでいくのか、組織の在り方や最適な手順を示し、課題を一緒に越えられるよう伴走致します。
                                </dd>
                            </dl>
                            <dl className="business-list">
                                <dt>BRANDING CONSULTING</dt>{' '}
                                <dd>
                                    企業や、商品、サービスに“アイデンティティ”を吹き込む、大切な工程をご支援します。「ブランド」とは何かを明確に定め、浸透させるプロセスには想像以上の困難があります。「ブランド」とは何か、という正しい理解を促す研修、0からのCIやBIの構築支援、内部への浸透活動の障害要因の洗い出しとその解決策、そして実際にブランドを表現するワーディングの開発や、協力企業と連携したヴィジュアルやマニュアル開発も行います。
                                </dd>
                            </dl>
                            <dl className="business-list">
                                <dt>MANAGEMENT SUPPORT</dt>{' '}
                                <dd>
                                    私が初めて管理職になったのが、24歳でした。その後、最も長く経験してきたのが「人」と協力しながらする仕事でした。正確にはメンバーを束ね、採用し、育成し、成果を出すこと。経営者の方、事業責任者の方、特定領域の責任者の方、新たに管理職になった方。皆さんは日々、「人」にまつわる何らかの悩み、課題を抱えながら仕事をしているかと思います。私もそうでした。ただ管理職は、メンバーの“人生”と“命”を預かっていると言っても過言ではありません。過去、彼らの目を見張るような成長、大きな成果を上げる姿を何度も目にしてきました。その経験を踏まえ、彼らとの向き合い方、採用、育成、成果に繋がる支援方法などを具体的に助言します。
                                </dd>
                            </dl>
                        </div>
                        <div className="business-right">
                            <img src={`${process.env.PUBLIC_URL}/images/Image-business.png`} alt="Business" />
                        </div>
                    </div>
                </div>
            </section>
            <section id="company" className="p-company">
                <div className="p-company__inner">
                    <div className="c-heading center">
                        <h2>会社概要</h2>
                    </div>
                    <div className="c-heading sub">
                        <h3>REPRESENTATIVE</h3>
                    </div>
                    <div className="p-company__wrap c-flex">
                        <div className="company-left">
                            <img src={`${process.env.PUBLIC_URL}/images/Image-owner.png`} alt="安藤裕也" />
                            <div className="profile">
                                <span className="role">代表取締役</span>
                                <span className="name">安藤 裕也</span>
                                <span className="ruby">YUYA ANDO</span>
                            </div>
                        </div>
                        <div className="company-right">
                            <p>
                                大学在学中にファッション雑誌の編集者としてキャリアをスタート。その後、版権売却のために内定先を失うも、ご縁を頂きディップ(株)に‘05年5月に入社。‘07年3月に最年少で課長に昇格し、管理職として組織運営、メンバー育成に従事。その後、グリー(株)に'10年9月入社。3rd
                                Party向けの広告事業の立ち上げメンバーとして入社し、同部署の責任者、オンラインプロモ責任者、GREE
                                PF領域の責任者を経て、グリー全体のマーケ、プロモーション領域の責任者を担う。'15年3月、マーケ組織の立ち上げをミッションとして(株)Schoo入社。取締役CMOとして全社のマーケの管轄を行いながら、法人事業責任者、新規事業の立ち上げを管掌し、同社初の黒字化を牽引。'18年5月、auコマース＆ライフ(株)入社。マーケティング本部の副本部長としてKDDI社と連携を推進しながら、運営サービスのマーケティング領域、ブランディング領域、広報領域、ブランド構築（CI・BI）、そして中期事業戦略策定をリード。'21年5月、Fam株式会社設立、代表取締役に就任。
                            </p>
                        </div>
                    </div>
                    <div className="c-heading sub">
                        <h3>COMPANY INFO</h3>
                    </div>
                    <div className="p-company__info">
                        <span className="company-name">Fam Inc.</span>
                        <span className="location">Dia Building 2F 2-2-15 Hamamatsu-cho Minato-ku, Tokyo 105-0013 JAPAN</span>
                        <span className="mail">MAIL : info@fam.inc</span>
                    </div>
                </div>
            </section>
            <section className="p-credit">
                <div className="p-credit__inner">
                    <p>SPECIAL THANKS TO MY "Fam"</p>
                    <ul className="credit-list">
                        <li>PHOTOGRAPHER : Keisuke Nagoshi</li>
                        <li>PHOTOGRAPHER : Kengo Yamaguchi</li>
                        <li>ILLUSTRATOR : Ryo Kaneyasu</li>
                    </ul>
                </div>
            </section>
        </div>
    );
};

export default Section;
