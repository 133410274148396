import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LazyLoad from 'react-lazyload';

const ImageSlider = () => {
    const images = [`${process.env.PUBLIC_URL}/images/Image-fv01.webp`, `${process.env.PUBLIC_URL}/images/Image-fv02.webp`];

    const settings = {
        fade: true,
        dots: false,
        autoplay: true,
        arrows: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div className="p-mv">
            <div className="p-mv__inner">
                <Slider {...settings}>
                    {images.map((url, index) => (
                        <div key={index}>
                            <LazyLoad height={200} offset={100}>
                                <img src={url} alt={`Slide ${index + 1}`} />
                            </LazyLoad>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default ImageSlider;
