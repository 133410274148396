// src/hooks/useBodyClass.js

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useBodyClass = (defaultClass) => {
    const location = useLocation();

    useEffect(() => {
        // Remove previous class
        document.body.className = '';

        // Add default class
        if (defaultClass) {
            document.body.classList.add(defaultClass);
        }

        // Add route-specific class
        const pathClass = location.pathname === '/' ? 'home' : location.pathname.replace('/', '');
        if (pathClass) {
            document.body.classList.add(pathClass);
        }

    }, [location, defaultClass]);
};

export default useBodyClass;
