import React, { useState } from 'react';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        company: '',
        message: ''
    });

    const [status, setStatus] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://fam.inc/backend/contact.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            setStatus(result.message);
            setSubmitted(true);
        } catch (error) {
            setStatus('エラーが発生しました');
            console.error('Error:', error);
        }
    };

    if (submitted) {
        return (
            <div>
                <main className="l-main">
                    <div className="l-main__inner">
                        <section className="p-contact">
                            <div className="p-contact__inner">
                                <div className="c-heading center">
                                    <h2>THANKS</h2>
                                </div>
                                <div className="p-contact__message">
                                    <p>お問い合わせ頂きありがとうございました。</p>
                                    <p>内容を確認し、担当より折り返しご連絡させていただきます。</p>
                                    <a href="/" class="form-btn">
                                        TOPへ戻る
                                    </a>
                                </div>
                            </div>
                        </section>
                    </div>
                </main>
            </div>
        );
    }

    return (
        <div>
            <main className="l-main">
                <div className="l-main__inner">
                    <section className="p-contact">
                        <div className="p-contact__inner">
                            <div className="c-heading center">
                                <h2>CONTACT</h2>
                            </div>

                            <div className="p-contact__form">
                                <form onSubmit={handleSubmit}>
                                    <label>
                                        <span>お名前</span>
                                        <input
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            placeholder="○○ ○太郎"
                                            className="input-placeholder"
                                            required
                                        />
                                    </label>
                                    <label>
                                        <span>会社名</span>
                                        <input
                                            type="text"
                                            name="company"
                                            value={formData.company}
                                            onChange={handleChange}
                                            placeholder="株式会社○○○"
                                            className="input-placeholder"
                                            required
                                        />
                                    </label>
                                    <label>
                                        <span>メールアドレス</span>
                                        <input
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            placeholder="○○○@yourmail.jp"
                                            className="input-placeholder"
                                            required
                                        />
                                    </label>
                                    <label>
                                        <span>お問い合わせ内容</span>
                                        <textarea
                                            name="message"
                                            value={formData.message}
                                            onChange={handleChange}
                                            placeholder="お仕事のご相談内容やパートナーシップのご依頼について詳細をご記入ください"
                                            className="input-placeholder"
                                            required
                                        />
                                    </label>
                                    <button type="submit" class="form-btn">
                                        送信
                                    </button>
                                </form>
                                {status && <p>{status}</p>}
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </div>
    );
};

export default ContactForm;
